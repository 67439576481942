<script lang="ts" setup>
import { buildBody } from '~/utils/dto/partner-user'

const { data: user } = useAuth()
const leftSwitch = ref()
const isVisible = ref(false)

onMounted(async () => {
  // Tuto visible uniquement si c'est un utilisateur partenaire enfant et qu'il a plusieurs enfants
  if (
    user.value.user._user_child &&
    user.value.user._partner_child_id_authorized_array.length > 1
  ) {
    isVisible.value = !user.value.user._welcome_tutorial_seen

    if (isVisible.value) {
      document.body.classList.add('overflow-hidden')
      const switchButton = document.querySelector('.account-switch__button')

      document.addEventListener('click', (e) => {
        if (isVisible.value) {
          setTimeout(() => {
            if (!e.target.closest('.account-switch__button')) {
              if (switchButton) switchButton.click()
            }
          }, 100)
        }
      })
      switchButton.click()
      setTimeout(() => {
        const switchGroup = document.querySelector('.account-switch .group')
        if (switchGroup) {
          leftSwitch.value =
            switchGroup.getBoundingClientRect().left -
            document.querySelector('.account-switch').offsetWidth +
            100
        }
      }, 100)
    }
  }
})

const close = () => {
  isVisible.value = false
  const switchButton = document.querySelector('.account-switch__button')
  setTimeout(() => {
    if (switchButton) switchButton.click()
  }, 100)

  document.body.classList.remove('overflow-hidden')

  useAuthPartnerUserUpdateFetch(
    buildBody(
      user.value.user,
      {},
      {
        WELCOME_TUTORIAL_SEEN: true,
      }
    )
  )
}
</script>

<template>
  <div
    v-if="isVisible"
    :style="{ left: `${leftSwitch}px` }"
    class="tuto-switch fixed z-50 max-w-[400px] rounded-6 bg-white p-24 drop-shadow-[0_6px_13px_rgba(0,0,0,0.2)] max-lg:!left-20 max-lg:bottom-20 max-lg:right-20 lg:top-200 lg:-translate-x-full"
  >
    <p class="text-p3">{{ $t('tuto.switch.title') }}</p>
    <UButton
      block
      class="mt-24"
      :label="$t('tuto.accept')"
      color="secondary"
      @click="close"
    />
  </div>
  <div
    v-if="isVisible"
    class="fixed bottom-0 left-0 right-0 top-84 z-10 bg-white bg-opacity-90"
  ></div>
</template>
