<script setup lang="ts">
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import formbricks from '@formbricks/js'
import axios from 'axios'
import type {
  strapiNavigationItem,
  strapiOptions,
} from '~/interfaces/strapi.interface'
import { useStore } from '~/store/main'

const { find, findOne } = useStrapi()
const { locale } = useI18n()
const store = useStore()
const { data: user } = useAuth()

const route = useRoute()
const config = useRuntimeConfig()

onMounted(async () => {
  window.sessionStorage.setItem('locale', locale.value)

  if (config.public.cookiebot) {
    const script = document.createElement('script')
    script.setAttribute('data-cbid', config.public.cookiebot)
    script.setAttribute('id', 'Cookiebot')
    script.setAttribute('data-blockingmode', 'auto')
    script.type = 'text/javascript'
    script.src = 'https://consent.cookiebot.com/uc.js'
    document.head.appendChild(script)
  }

  if (config.public.survey.env && config.public.survey.url && user.value) {
    formbricks.init({
      environmentId: config.public.survey.env,
      apiHost: config.public.survey.url,
      userId: user.value.user.id,
      attributes: {
        email: user.value.user.email,
        firstname: user.value.user.firstname,
        lastname: user.value.user.lastname,
      },
    })

    formbricks.track('open')
  }
})

const topMenu = ref()
const headerMenu = ref()
const footerMenu = ref()

const topNavigation =
  locale.value === 'fr'
    ? 'navigation/render/top-navigation?type=TREE'
    : 'navigation/render/top-navigation-en?type=TREE'

const { data: dataTop } = await useAsyncData('topNavigation', () =>
  find<strapiNavigationItem[]>(topNavigation)
)

if (dataTop.value) topMenu.value = dataTop.value
else topMenu.value = []

let headerNavigation: string

const { status } = useAuth()
if (locale.value === 'fr') {
  if (status.value === 'authenticated') {
    headerNavigation = 'navigation/render/header-navigation-connected?type=TREE'
  } else {
    headerNavigation = 'navigation/render/header-navigation?type=TREE'
  }
} else if (status.value === 'authenticated') {
  headerNavigation =
    'navigation/render/header-navigation-connected-en?type=TREE'
} else {
  headerNavigation = 'navigation/render/header-navigation-en?type=TREE'
}

const { data: dataHeader } = await useAsyncData('headerNavigation', () =>
  find<strapiNavigationItem[]>(headerNavigation)
)

if (dataHeader.value) headerMenu.value = dataHeader.value
else headerMenu.value = []

const footerNavigation =
  locale.value === 'fr'
    ? 'navigation/render/footer-navigation?type=TREE'
    : 'navigation/render/footer-navigation-en?type=TREE'

const { data: dataFooter } = await useAsyncData('footerNavigation', () =>
  find<strapiNavigationItem[]>(footerNavigation)
)

if (dataFooter.value) footerMenu.value = dataFooter.value
else footerMenu.value = []

// options
const { data: options } = await useAsyncData('options', () =>
  findOne<strapiOptions>('option', {
    populate:
      'url_match, socials, socials.icon, logo_header, logo_footer, contact_image, logo_blue, image_register, consent_image',
    locale: locale.value as StrapiLocale,
  })
)

onBeforeMount(() => {
  if (options.value && options.value.data && options.value.data.attributes.consent_image.data) {
    const link = document.createElement('link')
    link.setAttribute('rel', 'preload')
    link.setAttribute('as', 'image')
    link.setAttribute(
      'href',
      options.value.data.attributes.consent_image.data.attributes.url
    )
    document.head.appendChild(link)
  }
})

provide('options', options)
</script>

<template>
  <AkConsent
    :title="options?.data.attributes.consent_title"
    :info="options?.data.attributes.consent_info"
    :image="options?.data.attributes.consent_image"
    :text="options?.data.attributes.consent_text"
  />
  <div class="layout">
      <AkHeader :menu="[...headerMenu]" :top="[...topMenu]" />
      <slot />
      <TutoSwitch v-if="user && user.user.is_partner_user" />
      <AkFooter class="mt-80" :menu="[...footerMenu]" />
      <UNotifications />
      <NuxtLoadingIndicator color="#269999" />
  </div>
</template>

<style lang="scss">
//import fonts

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('@/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/Montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/Montserrat/Montserrat-Medium.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('@/assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('@/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('@/assets/fonts/Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/Inter/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/Inter/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('@/assets/fonts/Inter/Inter-SemiBold.ttf');
}

body {
  @apply font-inter text-[1rem] leading-normal text-black;
}

span[class^='i-fa6-'] {
  mask-size: contain !important;
  mask-position: center !important;
}

.layout {
  @media (min-width: 1280px) {
    min-height: calc(100vh - 82px);
    margin-top: 82px;
    display: grid;
    grid-template-rows: 1fr auto;
  }
}

.aspect-video {
  aspect-ratio: 16/9;
}

.text-h1 {
  @apply font-montserrat text-42 leading-normal lg:text-64 lg:leading-none;
}

.text-h2 {
  @apply font-montserrat text-34 leading-normal lg:text-62 lg:leading-none;
}

.text-h3 {
  @apply font-montserrat text-30 font-semibold leading-normal lg:text-48;
}

.text-h4 {
  @apply font-montserrat text-24 font-semibold leading-none tracking-[-0.48px] lg:leading-normal;
}

.text-h5 {
  @apply font-montserrat text-20 font-semibold leading-32 lg:font-normal;
}

.text-h6 {
  @apply font-montserrat text-18 font-semibold leading-normal;
}

.text-basic {
  @apply text-14 font-bold leading-normal;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

#CookiebotWidget {
  display: none;
}

.custom-checkbox {
  label {
    margin-top: 2px;
  }
}

.dropdown {
  .dropdown__chevron {
    @apply transition;
  }

  &[data-headlessui-state='open'] .dropdown__chevron {
    transform: rotate(180deg);
  }
}
</style>
